<template>
      <el-row style="z-index:99;background:#fff;box-shadow: 0 2px 3px 0 rgb(0 0 0 / 6%);">
        <el-col :span="5">
          <div class="header_logo">
            <router-link to='/'>
              <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" >
              <span >经济运行平台</span>
            </router-link>
          </div>
        </el-col>
        <el-col :span="2">
          <!-- 地区列表选择 -->
          <div style="margin-top:15px;text-align:center;">
          <el-popover popper-class="region-popper" :width="560" trigger="hover">
            <template #reference>
              <el-button type="text"  icon="iconfont  ec-icon-location mr-5">
              {{regionName}}
              </el-button>
            </template>
            <div>
              <div>
                <div>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("110000","北京市",1)'>北京市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("120000","天津市",1)'>天津市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("310000","上海市",1)'>上海市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("500000","重庆市",1)'>重庆市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("330100","杭州市",2)'>杭州市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("320100","南京市",2)'>南京市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("430100","长沙市",2)'>长沙市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("420100","武汉市",2)'>武汉市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("610100","西安市",2)'>西安市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("370200","青岛市",2)'>青岛市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("340100","合肥市",2)'>合肥市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("410100","郑州市",2)'>郑州市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("320500","苏州市",2)'>苏州市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("440100","广州市",2)'>广州市</el-link>
                  <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("440300","深圳市",2)'>深圳市</el-link>
                </div>
                <div style="border-bottom:1px solid #EBEEF5;">
                  <el-link class="city_letter" :underline="false" v-on:click.prevent='goAnchor(".r_"+item)' v-for="item in alphabet" :key="item">{{item}}</el-link>
                </div>
              </div>
                <el-table
                  class="scroll_cls" 
                  :data="regionList"
                  style="width: 100%"
                  max-height='350px'
                  :span-method="objectSpanMethod"
                  :show-header='false' 
                  :row-class-name='rowKeyFun'
                  >
                  <el-table-column
                    prop="chineseToEn"
                    label="字母"
                    width="40">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="省"
                    width="80">
                    <template #default="scope">
                      <el-link :underline="false" style="font-weight:600;" v-on:click.prevent='selectRegion(scope.row.code,1)'>{{scope.row.name}}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="children"
                    label="市">
                    <template #default="scope">
                      <div v-if=" ',11,12,31,50,'.indexOf(''+scope.row.code+',')<0">
                        <el-link style='padding:0 3px;' :underline="false" v-for="child in scope.row.children" :key="child.code" v-on:click.prevent='selectRegion(child.code,2)' >{{child.name}}</el-link>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
          </el-popover>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-menu 
            :default-active="currentMenu" 
            mode="horizontal" 
            active-text-color="$themeColor"
            @select="handleSelect">
              <el-menu-item :route="m.path" v-for="m in menuList" :key="m.name" :index="m.name">{{m.meta.title}}</el-menu-item>
            </el-menu>
          </div>
        </el-col>
       
        <el-col :span="9" style="display: flex;align-items: center;justify-content: end;padding-right:20px;" >
           <!-- 应用列表 -->
            <div class="saas_header_all">
              <el-popover  trigger="hover" @show="isShowApp=true;" @hide="isShowApp=false;" :width='186'>
                <template #reference>
                  <span>
                    <i class="iconfont ec-icon-menu mr-5"></i>
                    <span style="font-weight:700;font-size: 1.1em;" >应用 </span> 
                    <i class='iconfont ec-icon-caret-bottom' style="display:inline-block;transition: 0.2s linear;" :style="{'transform': 'rotate('+(isShowApp?180:0)+'deg)'}"></i>
                  </span>
                </template>
                <div>
                    <ul class="applist">
                      <li v-for="item in appList" :key="item.name" >
                        <el-link  :underline="false" @click.prevent="pushSearch(item.path,item.name)">
                          <span class="applist-icon">
                            <i class="iconfont" :class="item.meta.icon" ></i>
                          </span>
                          <br>
                          {{item.meta.title}}
                        </el-link>
                      </li>
                      <!-- <li>
                        
                        <el-link  :underline="false" @click.prevent="pushSearch('/investment/index','investment')">
                          <span class="applist-icon">
                            <i class="iconfont ec-icon-investment" ></i>
                          </span>
                          <br>
                          精准招商
                        </el-link>
                      </li> -->
                    </ul>
                </div>
              </el-popover>
            </div>
            <!-- 指标搜索 -->
            <div >
                <el-input v-model="searchStr"  placeholder="指标项名，如：地区生产总值" @keydown.enter="pushSearch('/search','search')">
                <template #append>
                    <el-button icon="iconfont  ec-icon-search" @click="pushSearch('/search','search')" ></el-button>
                    <!-- <el-link :underline="false" icon="el-icon-search" target='_blank' :href='"/#/EconoSearch?key="+searchStr'> </el-link> -->
                </template>
              </el-input>
            </div>
             <!-- 开通会员 -->
            <el-link href="#/help/auth" class="saas_header_all saas_vip" :underline="false"> 
              <i class="iconfont ec-icon-VIPS saas_i_vip"></i>
              <div>开通会员</div>
            </el-link>
          <div >
            <!-- <el-link v-if="!currentUser || currentUser?.expires<Math.round(new Date().getTime()/1000)" :underline="false" type="primary" @click.prevent='$store.commit("setIsLoginVisable", true);'>登录/注册</el-link> -->
            <el-link v-if="!currentUser" :underline="false" type="primary" @click.prevent='$store.commit("setIsLoginVisable", true);'>登录/注册</el-link>
            <el-popover v-else placement="bottom" :width="200" trigger="hover" >
              <template #reference>
                <el-avatar   :size="30" :src="currentUser?.picUrl" style="cursor: pointer;" ></el-avatar>
              </template>
              <div class="user-float" >
                <div class="user-name">
                  <span style="">{{currentUser?.userName}}</span>
                  <!-- <br>
                  <span>截止时间：{{currentUser.deadline}}</span> -->
                </div>
                <ul>
                  <!-- <li class="css-1e76yen" @click="loginOut">
                    <i class="el-icon-switch-button"></i>
                    <span>修改密码</span>
                  </li> -->
                  <li class="css-1e76yen" @click="pushSearch(`/people`,`people`)">
                    <i class="iconfont ec-icon-s-user"></i>
                    <span>我的主页</span>
                  </li>
                  <li class="css-1e76yen" @click="pushSearch('/user-center','usercenter')">
                    <i class="iconfont ec-icon-setting-fill"></i>
                    <span>个人中心</span>
                  </li>
                  <li class="css-1e76yen" @click="loginOut">
                    <i class="iconfont ec-icon-exit"></i>
                    <span>退出</span>
                  </li>
                </ul>
              </div>
            </el-popover>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        v-model="$store.state.loginVisable"
        title=""
        :width='420'
        custom-class="login-dialog"
        destroy-on-close 
        :close-on-click-modal='false'
        @close='loginCloseFun'>
        <login></login>
      </el-dialog>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import router from '@/router/index.js'
import {ref, reactive, computed, inject,watch} from 'vue'
import Login from '@/components/Login'
import {getUserRightFun} from '../http/basicsApi'
//import {getIsLogin,/*getUserInfo*/} from '@/http/basicsApi'
import {scrollTo} from '@/utils/scroll-to'
import store from '../store';
export default {
  name: 'Header',
  components:{
    Login
  },
  setup(){
    let searchStr=ref('');//搜索内容
    const route = useRoute();//当前路由
    const router_=useRouter();//路由
    //console.log(router_.getRoutes());
    // let navMenuData=JSON.parse(localStorage.getItem("navMenuData"));
    // console.log(navMenuData);
    const menuData=router_.getRoutes()
    let currentMenu=ref();
    let menuList=reactive(menuData.filter(t=>',home,econodev,industry,ep,'.indexOf(','+t.name.toLowerCase()+',')>=0))
    .sort(function (a, b) {//升序
        if(a.meta.sort==null || b.meta.sort==null) return -1
        return a.meta.sort - b.meta.sort;
    });
    currentMenu.value=route.matched[0].name
    console.log(menuList);
    let appList=reactive(menuData.filter(t=>',industrychain,report,'.indexOf(','+t.name.toLowerCase()+',')>=0))
    .sort(function (a, b) {//升序
        if(a.meta.sort==null || b.meta.sort==null) return -1
        return a.meta.sort - b.meta.sort;
    });

    let reload=inject('reload');
    
    // if(route.matched[0].name=='IndustryChainInfo'){
    //   currentMenu.value='IndustryChainList';
    // }
    // // else if(route.matched[0].name=='IndustryProvinceAnalysis'){
    // //   currentMenu.value='IndustryCityAnalysis';
    // // }
    // else{
    //     currentMenu.value=route.matched[0].name
    // }

    //let isLogin=computed(()=>localStorage.getItem('accesstoken')?true:false);//是否登录
    //console.log(isLogin.value);
    
    let currentUser=computed(()=>JSON.parse( localStorage.getItem('currentUser')));//当前用户信息
    let isShowApp=false;//应用的显示
    //console.log(currentUser.value);
    //console.log(Math.round(new Date().getTime()/1000));
    let regionList= inject('regionList');
    let alphabet=inject('alphabet');
    let regionCode=computed(()=>store.state.curRegionCode);//ref(route.query.regionCode);

    let regionName=ref('');
    //let regionLevel=ref(0);
    if(!regionCode.value){
      store.commit('setCurRegionCode');
      regionCode=computed(()=>store.state.curRegionCode);
    }
    //console.log(region);
    //获取当前地区的名称
    const getRegionInfoFun=(regionCode)=>{
      //如果地区编号为空，则取当前用户所在地区
      //console.log(regionCode);
      let isStop=false;
      if(regionCode){
        regionList.value.forEach(element => {
          if(element.code==regionCode)
          {
            regionName.value=element.name;
            return
          }
          element.children.forEach(child => {
            if(child.code==regionCode)
            {
              regionName.value=child.name;
              isStop=true;
              return
            }
          });
          if(isStop){
            return;
          }
        });
      }
      //region.name='张家口市';
      //region.code=regionCode;
      
    }
     //监视属性
     watch(
      () => regionList.value,
      (newVal) => {
        //console.log("监听地区列表到变化拉！！",newVal)
        if(newVal && regionCode.value){
          getRegionInfoFun(regionCode.value)
        }
        
      },
      {
      immediate:true,// 这个属性是重点啦
      //deep:true // 深度监听的参数
      }
    );
    watch(
      () => regionCode.value,
      (newVal) => {
        //console.log("监听region到变化拉！！",newVal)
        if(newVal && regionList.value){
          getRegionInfoFun(newVal)
        }
      },
      {
      immediate:true,// 这个属性是重点啦
      //deep:true // 深度监听的参数
      }
    );
    
    
    //地区列表————table 合并列
    let prevSpanObj='-1';
    const objectSpanMethod=({ row, column, rowIndex, columnIndex })=> {// eslint-disable-line no-unused-vars
      if (columnIndex === 0) {
        //首先要确定以字母字段进行排序，过滤地区列表，
        let filterArrary=regionList.value.filter((item)=>{
          return item.chineseToEn==row.chineseToEn;
        })
        if (prevSpanObj != row.chineseToEn) {
          prevSpanObj=row.chineseToEn;
          return {
            rowspan: filterArrary.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
    //地区列表————给行绑定不一样的样式，为了锚点定位
    let prevClass='-1';
    const rowKeyFun=(row)=>{
      if(prevClass!=row.row.chineseToEn){
        prevClass=row.row.chineseToEn;
        return 'r_'+row.row.chineseToEn;
      }
      else{
        return ''
      } 
    }
    //地区列表————锚点跳转
    const goAnchor= (selector) => {
      let to=document.querySelector(selector).offsetTop;
      scrollTo('.scroll_cls .el-table__body-wrapper',to,800);
    }
    //地区选择，刷新页面
    const selectRegion=async (code,level)=>{// eslint-disable-line no-unused-vars
      if(await getUserRightFun('home',0)){
        store.commit('setCurRegionCode',code);
        router.push('/home');
      }
      
      //淄博市项目平台调整 2023-04-17
      // if(route.path=='/' || route.path=='/index'){
      //   reload();
      // }
      // else{
      //     router.push('/')
      // }


      // let path=route.path;
      // if(level==1 && route.name.toLowerCase()=="industrycityanalysis"){
      //   path='/industry/provinceanalysis'
      // }
      // else if(level==2 && route.name.toLowerCase()=="industryprovinceanalysis"){
      //   path='/industry/cityanalysis'
      // }
      // router.push({
      //   path: path,
      //   query: {
      //       regionCode: code
      //   }
      // });
    }
    ///获取用户信息
    // let userInfo=ref(null);//用户信息
    // const getUserInfoFun=async()=>{
    //   console.log('用户信息');
    //   if(currentUser.value){
    //     let result= await getUserInfo(currentUser.value.userId)
    //     console.log(result);
    //     if(result.code==20000){
    //       userInfo.value=result.data;
    //     }
    //   }
      
    // }
    //搜索跳转
    const pushSearch= async (path,name)=>{
      console.log('路由判断页面是否可以访问',name);
      if(await getUserRightFun(name,0) || name=='investment'){
        let href_='';
        if(name=='search'){
          const { href } = router_.resolve({
            path: path,
              query: {
                  key: searchStr.value
              }
          });
          href_=href;
        }
        else{
          const { href } = router_.resolve({
            path: path,
          });
          href_=href;
        }
        console.log(href_);
        window.open(href_, "_blank");
      }
          
    }
    //功能菜单的选择事件
    const handleSelect=async (index,indexPath,item)=>{// eslint-disable-line no-unused-vars
      console.log(index);
      console.log(indexPath);
      console.log(item);
      // getIsLogin().then((res)=>{
        //console.log(res);
        //if(res && res.code==20000){//登录成功
          //router.push(item.route)
          //router.push({name:index})
          //console.log(regionLevel.value);
          // if(regionLevel.value==1 && index.toLowerCase()=='industrycityanalysis'){
          //   router.push({
          //     path: '/industry/provinceanalysis',
          //   });
          // }else{
                  //判断是否有权限访问此页面
            console.log('路由判断页面是否可以访问',index);
            if(await getUserRightFun(index,0)){
              console.log('有权执行后面的',item.route);
              router.push(item.route)
            }
            
          //}
          
        //}
      //})
      //默认子菜单(右侧菜单)是展开的
      //store.dispatch('closeSideBar', false);
    }
    //退出
    const loginOut=()=>{
      //loginOut('xxx').then(response=>{// eslint-disable-line no-unused-vars
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("currentUser");
          localStorage.removeItem("refreshToken"); 
          if(route.path=='/' || route.path=='/index'){
              reload();
          }
          else{
              router.push('/')
          }
      // }).catch(error=>{
      //  console.log(error);
      //  alert("网络错误，请求失败");
      // })
      // localStorage.removeItem("accesstoken");
      // localStorage.removeItem("currentUser");
      // localStorage.removeItem("refreshToken"); 
      // if(currentMenu.value=='Home'){
      //   reload();
      // }
      // else{
      //   router.push("/");
      // }
      //store.dispatch('loginOut',router);
    }
    //跳转
    const push=(path)=>{
      //console.log(path);
      router.push(path);
    }
    //关闭登录对话框跳转到首页
    const loginCloseFun=()=>{
      push('/')
    }
    //getUserInfoFun();
    return {
      //userInfo,
      isShowApp,
      regionName,
      regionList,
      alphabet,
      objectSpanMethod,
      rowKeyFun,
      goAnchor,
      selectRegion,
      searchStr,
      menuList,
      appList,
      currentMenu,
      handleSelect,
      pushSearch,
      //isLogin,
      currentUser,
      loginOut,
      loginCloseFun,
      push
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.header_logo{
  margin-top: 17px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  img{
    vertical-align: bottom;
  }
  span{
    line-height: 35px;
    font-size: 22px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $specialtext-color;
    color: $specialtext-color;
    white-space: normal;
    white-space: nowrap;
  }
}
.saas_header_all{
		margin: 0 10px;
		cursor: pointer;
	}
  .saas_vip{
    line-height: 12px;
    background: rgba(255,228,196,0.3);
    padding: 6px;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
  }
.saas_i_vip{
  color:$themeColor;
}
  .city_letter{
    margin:5px;
    padding: 0px 6px;
    background-color: rgba($themeColor,.05);
    border-radius: 2px;
  }
  .applist li{
    text-align:center;
    float: left;
    width:80px;
    a{
      font-size:16px;
    }
    
  }
  .applist-icon{
    background: rgba($themeColor,0.2);
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: $themeColor;
    margin-bottom: 5px;
    i{
      font-size: 30px;
    }
  }
  ::v-deep .el-table_1_column_1  {
    color:#aaa;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: transparent;
}
</style>
