import { nextTick } from "@vue/runtime-core"

Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2
    if (t < 1) {
      return c / 2 * t * t + b
    }
    t--
    return -c / 2 * (t * (t - 2) - 1) + b
  }
  
  // requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
  var requestAnimFrame = (function() {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function(callback) { window.setTimeout(callback, 1000 / 60) }
  })()
  
  /**
   * Because it's so fucking difficult to detect the scrolling element, just move them all
   * @param {number} amount
   */
  function move(selector, amount) {
    //document.querySelector(selector).scrollTop = amount
    //document.body.parentNode.scrollTop = amount
    nextTick(()=>{
      if(selector){
        const div= document.querySelector(selector);// ='900px';// amount
        div.scrollTop=amount;
      }
      else{
        document.body.scrollTop=document.documentElement.scrollTop = amount
      }
    })
  }
  
  function position(selector) {// eslint-disable-line no-unused-vars
    //return document.querySelector(selector).scrollTop
    let scroll_top=0;
    if(selector){
      scroll_top=document.querySelector(selector).scrollTop
    }
    else{
      scroll_top=document.body.scrollTop;
      if(scroll_top==0){
        scroll_top=document.documentElement.scrollTop;
      }
    }
    return scroll_top;
  }
  
  /**
   * @param {string} selector
   * @param {number} to
   * @param {number} duration
   * @param {Function} callback
   */
  export function scrollTo(selector,to, duration, callback) {
    const start = position(selector)
    const change = to - start
    const increment = 20
    let currentTime = 0
    duration = (typeof (duration) === 'undefined') ? 500 : duration
    var animateScroll = function() {
      // increment the time
      currentTime += increment
      // find the value with the quadratic in-out easing function
      var val = Math.easeInOutQuad(currentTime, start, change, duration)
      // move the document.body
      move(selector,val)
      // do the animation unless its over
      if (currentTime < duration) {
        requestAnimFrame(animateScroll)
      } else {
        if (callback && typeof (callback) === 'function') {
          // the animation is done so lets callback
          callback()
        }
      }
    }
    animateScroll()
  }
  